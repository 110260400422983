<template>
  <div
    data-ui="events-home"
    class="events-home"
    @click="global.onPageClick"
  >
    <SearchBanner
      v-if="leadIn"
      :heading="leadIn.heading"
      :description="leadIn.description"
      :image="leadIn.image"
      :activities="leadIn.activities"
      :default-activity="leadIn.defaultActivity"
      :distances="leadIn.distances"
      :default-distance="leadIn.defaultDistance"
    />
    <CdrContainer class="events-home__components">
      <PromoComponent
        v-for="(promo, idx) in models.promotions"
        :key="`${promo.heading}${idx}`"
        :heading="promo.heading"
        :description="promo.description"
        :image="promo.image"
        :link="promo.link"
      />
      <FeaturedExperiences
        :heading="models.featuredExperiences.heading"
        :experiences="models.featuredExperiences.experiences"
      />
      <CategoryIcons />
      <VerticalStory
        v-for="(regionalPromo, idx) in models.regionalPromotions"
        :key="`${regionalPromo.heading}${idx}`"
        :heading="regionalPromo.heading"
        :description="regionalPromo.description"
        :image="regionalPromo.image"
        :link="regionalPromo.link"
      />
      <AboutCoopExperiences
        :heading="models.aboutCoopExperiences.heading"
        :benefits="models.aboutCoopExperiences.benefits"
      />
    </CdrContainer>
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, type PropType } from 'vue';
import { CdrContainer } from '@rei/cedar';
import SearchBanner from '../../components/events-home/SearchBanner.vue';
import PromoComponent, {
  type PromoComponentProps,
} from '../../components/global/contentstack/PromoComponent.vue';
import FeaturedExperiences, {
  type FeaturedExperiencesProps,
} from '../../components/events-home/FeaturedExperiences.vue';
import CategoryIcons from '../../components/events-home/CategoryIcons.vue';
import VerticalStory, {
  type VerticalStoryProps,
} from '../../components/global/contentstack/VerticalStory.vue';
import AboutCoopExperiences, {
  type AboutCoopExperiencesProps,
} from '../../components/events-home/AboutCoopExperiences.vue';
import { useGlobal } from '../../composables/global.mts';
import useLivePreview from '../../composables/livePreview.mts';
import { activities, distances } from '../../components/events-home/data.mts';
import type { Image } from '../../types/contentstack.types';
import type { NameValuePair } from '../../types/shared.types.mjs';

defineOptions({ name: 'EventHome', inheritAttrs: false });

// TODO: Vue doesn't support Generics in Props, needs to be refactor latter
export interface EventsHome extends Record<symbol, never> {
  uid: string;
  heading?: string;
  description?: string;
  image: Image;
  promotions: PromoComponentProps[];
  featuredExperiences: FeaturedExperiencesProps;
  regionalPromotions: VerticalStoryProps[];
  aboutCoopExperiences: AboutCoopExperiencesProps;
}

export interface GenericPageModel {
  componentModels: EventsHome;
  analyticsData: PropType<NameValuePair> | null;
  livePreview?: boolean;
}

// Component properties
const props = withDefaults(defineProps<GenericPageModel>(), {
  livePreview: true,
});

// Contentstack LivePreview configuration
const { modelsRef: livePreviewModels } = useLivePreview(
  '/experiences/events-home',
  props.componentModels,
  {
    livePreview: props.livePreview,
  },
);

const analyticsOverrides = { contentstackUrl: '/events' };
const global = useGlobal({ props, analyticsOverrides });
const models: ComputedRef<EventsHome> = computed(() =>
  props.livePreview
    ? (livePreviewModels.value as EventsHome)
    : (props.componentModels as EventsHome),
);

const leadIn = computed(() => {
  const { heading, description, image } = models.value;

  if (!heading || !description || !image) {
    return null;
  }

  return {
    heading,
    description,
    image,
    activities,
    defaultActivity: 0,
    distances,
    defaultDistance: '100',
  };
});
</script>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.events-home {
  &__item {
    &:not(:first-child):not(.sticky-sub-nav) {
      margin-top: $cdr-space-two-x;
    }

    &:last-child:not(.banner-component) {
      margin-bottom: $cdr-space-two-x;
    }
  }
}
</style>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.events-home {
  a:not([class*='cdr-button']) {
    color: $cdr-color-text-link-rest;

    &:focus {
      outline: $cdr-space-eighth-x solid Highlight;
      outline-color: -webkit-focus-ring-color;
      outline-offset: 0;
    }

    &:focus,
    &:active {
      color: $cdr-color-text-link-active;
    }

    &:hover {
      color: $cdr-color-text-link-hover;
      text-decoration: none;
    }
  }

  &__components {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $cdr-space-two-x;
    padding-top: $cdr-space-two-x;
    padding-bottom: $cdr-space-two-x;
  }
}
</style>
